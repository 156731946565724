import React from 'react'
import {Divider, ListItem} from '.'
import styled from 'styled-components'
import {calculatePercentageValue, formatCurrency} from '../utils'
import {Gender} from '../types'
import {MdCheck} from 'react-icons/md'
import {
  BREAST_RECONSTRUCTIVE_SURGERY_PERCENTAGE,
  CANCER_RELAPSE_PERCENTAGE,
  CANCER_TESTING_PERCENTAGE,
  CHILD_CANCER_PERCENTAGE,
} from '../constants'

type Props = {
  payout: number
  gender: Gender
}

export const BenefitsList: React.FC<Props> = ({payout = 0, gender}) => {
  return (
    <ListContainer>
      <ListDivider />
      <CoverListItem>
        <>
          <AcceptText>Main Benefit - We Pay You</AcceptText>
        </>
        <>
          <AcceptValue>R{formatCurrency(payout)}</AcceptValue>
        </>
      </CoverListItem>
      <CoverListItem>
        <CoverListColumn>
          <CoverListRow>
            <>
              <AcceptText>Additional Payment Benefits</AcceptText>
            </>
            <>
              <AcceptValue>Included</AcceptValue>
            </>
          </CoverListRow>
          <CoverListRow>
            <CheckItem>
              <CheckItemText bold={true}>Cancer Relapse</CheckItemText>
            </CheckItem>
            <BenefitPercentage>
              R
              {formatCurrency(
                calculatePercentageValue(CANCER_RELAPSE_PERCENTAGE, payout),
              )}
            </BenefitPercentage>
          </CoverListRow>
          {gender === 'female' && (
            <CoverListRow>
              <CheckItem>
                <CheckItemText bold={true}>
                  Breast Reconstructive Surgery
                </CheckItemText>
              </CheckItem>
              <BenefitPercentage>
                R
                {formatCurrency(
                  calculatePercentageValue(
                    BREAST_RECONSTRUCTIVE_SURGERY_PERCENTAGE,
                    payout,
                  ),
                )}
              </BenefitPercentage>
            </CoverListRow>
          )}
          <CoverListRow>
            <CheckItem>
              <CheckItemText bold={true}>
                Cancer Precision Testing
              </CheckItemText>
            </CheckItem>
            <BenefitPercentage>
              R
              {formatCurrency(
                calculatePercentageValue(CANCER_TESTING_PERCENTAGE, payout),
              )}
            </BenefitPercentage>
          </CoverListRow>
          <CoverListRow>
            <CheckItem>
              <CheckItemText bold={true}>Child Cancer Benefit</CheckItemText>
            </CheckItem>
            <BenefitPercentage>
              R
              {formatCurrency(
                calculatePercentageValue(CHILD_CANCER_PERCENTAGE, payout),
              )}
            </BenefitPercentage>
          </CoverListRow>
        </CoverListColumn>
      </CoverListItem>
      <CoverListItem>
        <CoverListColumn>
          <CoverListRow>
            <>
              <AcceptText>Added Value Benefits</AcceptText>
            </>
            <>
              <AcceptValue>Included</AcceptValue>
            </>
          </CoverListRow>
          <CoverListRow>
            <CheckItem>
              <CheckItemText bold={false}>Aynjil Care</CheckItemText>
            </CheckItem>
            <IconWrapper>
              <MdCheck />
            </IconWrapper>
          </CoverListRow>
          <CoverListRow>
            <CheckItem>
              <CheckItemText bold={false}>Aynjil Assist</CheckItemText>
            </CheckItem>
            <IconWrapper>
              <MdCheck />
            </IconWrapper>
          </CoverListRow>
          <CoverListRow>
            <CheckItem>
              <CheckItemText bold={false}>Aynjil Second Opinion</CheckItemText>
            </CheckItem>
            <IconWrapper>
              <MdCheck />
            </IconWrapper>
          </CoverListRow>
        </CoverListColumn>
      </CoverListItem>
      <CoverListItem>
        <>
          <AcceptText>Policy Start Date</AcceptText>
        </>
        <>
          <AcceptValue>Today</AcceptValue>
        </>
      </CoverListItem>
    </ListContainer>
  )
}

const ListDivider = styled(Divider)`
  margin-top: var(--spacing-unit);
`

const ListContainer = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const CoverListItem = styled(ListItem)`
  @media (min-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const CoverListColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const CoverListRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
`

const CheckItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--spacing-unit) / 2);
  padding-left: 8px;
`

const CheckItemText = styled.span<{bold: boolean}>`
  font-size: 0.813rem;
  font-weight: ${(props) =>
    props.bold ? 'var(--font-bold)' : 'var(--font-regular)'};
  text-align: left;
  color: var(--black);

  span.benefitPayment {
    display: block;
    font-weight: var(--font-regular);
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(var(--spacing-unit) / 2);
  width: 60px;
  margin-left: calc(var(--spacing-unit) * 2); ;
`

const AcceptText = styled.span`
  font-size: 0.875rem;
  font-weight: var(--font-medium);
  text-align: left;
  color: var(--black);
`

const AcceptValue = styled.span`
  font-size: 0.875rem;
  font-weight: var(--font-bold);
`

const BenefitPercentage = styled.span`
  font-size: 0.8rem;
  font-weight: var(--font-regular);
  margin-top: calc(var(--spacing-unit) / 2);
`
