import {createGlobalStyle} from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  /**************************
  app-wide variables 
  **************************/
  :root {
    /* colors */
    --purple: #36008D;
    --lavendar: #F4F3FF;
    --white: #FFFFFF;
    --orange: #ffac03;
    --dark-purple: #10002B;
    --light-purple: #8F85ED;
    --text-purple: #6A3CC7;
    --gray: #999999;
    --black: #000000;
    --red: #E30613;
    /* spacing */
    --spacing-unit: 16px;
    /* fonts */
    --font-family: 'Inter', Arial, Helvetica, sans-serif;
    --font-light: 300;
    --font-regular: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 800;
  }
  /**************************
  global styles
  **************************/
  * {
    box-sizing: border-box;
  }
  html, body, #__next {
    margin: 0;
    padding: 0;
    font-size: var(--spacing-unit);
    font-family: var(--font-family);
    font-weight: var(--font-regular);
    color: var(--text-purple);
    background-color: var(--lavendar);
  }

  h1, .h1 {
    margin: 0 0 10px 0;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: var(--font-bold);
    color: var(---text-purple);

    @media (min-width: 768px) {
      font-size: 3.125rem;
      line-height: 3.75rem;
      margin: 0 0 var(--spacing-unit);
    }
  }
  h2, .h2 {
    margin: 0 0 var(--spacing-unit) 0;
    font-size: 1.25rem;
    line-height: 1.375rem;
    font-weight: var(--font-bold);
    color: var(--text-purple);

    @media (min-width: 768px) {
      font-size: 1.75rem;
      line-height: 2.125rem;
    }
  }
  h3, .h3 {
    margin: 0 0 var(--spacing-unit);
    font-size: 1rem;
    line-height: 1.188rem;
    font-weight: var(--font-regular);
    color: var(--purple);

    @media (min-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.813rem;
      margin: 0 0 calc(var(--spacing-unit) * 2.5)
    }

    @media (min-width: 992px) {
      margin: 0 0 calc(var(--spacing-unit) * 1.5)
    }
  }
  h4, .h4 {
    margin: 0 0 var(--spacing-unit) 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: var(--font-regular);
    color: var(--purple);

    @media (min-width: 768px) {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }
  h5, .h5 {
    margin: calc(-var(--spacing-unit) / 2) 0 var(--spacing-unit) 0;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: var(--font-light);

    @media (min-width: 768px) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.188rem;
    font-weight: var(--font-light);
  }
  a {
    color: var(--purple);
    font-weight: var(--font-semibold);
  }
  li {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: var(--font-light);
  }
  .bold {
    font-weight: var(--font-bold);
  }
  .text-purple {
    color: var(--text-purple) !important;
  }

  // spinner
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: -30px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--text-purple);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
