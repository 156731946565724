import React, {useState} from 'react'
import styled from 'styled-components'
import {Gender} from '../types'

type Props = {
  value: Gender
  onChange: (gender: Gender) => void
}

export const GenderSwitch: React.FC<Props> = ({value, onChange}) => {
  const [selectedGender, setSelectedGender] = useState<Gender>(value)

  const renderRadio = (gender: Gender) => {
    const isSelected = selectedGender === gender

    return (
      <Radio checked={isSelected} reverse={gender === 'female'}>
        <HiddenRadio
          value={gender}
          checked={isSelected}
          name={gender}
          onChange={() => {
            setSelectedGender(gender)
            onChange(gender)
          }}
          id={gender}
          data-testid={`hidden-radio-${gender}`}
        />
        <img
          src={`/images/${gender}-selected.svg`}
          alt={`selected ${gender}icon`}
          width={24}
          height={48}
          className="icon-selected"
        />
        <img
          src={`/images/${gender}.svg?v=2`}
          alt={`${gender} icon`}
          width={24}
          height={48}
          className="icon-default"
        />
        <Label htmlFor={gender} selected={isSelected}>
          {gender === 'male' ? 'Male' : 'Female'}
        </Label>
      </Radio>
    )
  }

  return (
    <Wrapper>
      <RadioContainer>
        {renderRadio('male')}
        {renderRadio('female')}
      </RadioContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) / 2);

  @media (min-width: 768px) {
    padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) / 2);
    max-width: 484px;
  }
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lavendar);
  border-radius: 100px;
  height: 80px;
  padding: 0 3px;
`

const Radio = styled.label<{checked: boolean; reverse: boolean}>`
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  width: 74px;
  height: 74px;
  border-radius: 74px;
  background-color: ${(props) =>
    props.checked ? 'var(--text-purple)' : 'transparent'};
  width: 50%;

  .icon-default {
    display: ${(props) => (props.checked ? 'none' : 'block')};
  }

  .icon-selected {
    display: ${(props) => (props.checked ? 'block' : 'none')};
  }
`

const HiddenRadio = styled.input.attrs({type: 'radio'})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const Label = styled.label<{selected: boolean}>`
  color: ${(props) => (props.selected ? 'var(--white)' : 'var(--purple)')};
  cursor: pointer;
  font-weight: var(--font-bold);
  margin: 0 12px;
`
