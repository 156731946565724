import React from 'react'
import styled from 'styled-components'

export const ListItem: React.FC = (props) => {
  const {children} = props
  return <Wrapper {...props}>{children}</Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--spacing-unit);
  border-bottom: 1px solid #d7d4f9;

  @media (min-width: 768px) {
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 1.5);
  }

  @media (min-width: 992px) {
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
  }
`
