import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {MdIosShare as ShareIcon, MdClose as CloseIcon} from 'react-icons/md'
import {TextInput} from './textInput'
import {SegmentedControl} from './segmentedControl'
import * as Yup from 'yup'
import {Button} from './button'
import {useFormik} from 'formik'
import {useContactNumberMask} from '../hooks/useContactNumberMask'
import axios from 'axios'
import {useAppSelector} from '../hooks'
import {
  calculatePercentageValue,
  formatCurrency,
  getEnvironment,
} from '../utils'
import {
  CANCER_RELAPSE_PERCENTAGE,
  CANCER_TESTING_PERCENTAGE,
  CHILD_CANCER_PERCENTAGE,
} from '../constants'
import {ApiCMEvent} from '../types'

type FormValues = {
  recipientFirstName: string
  recipientLastName: string
  sentBy: string
  referralCode?: string
  deliveryMethod: 'Whatsapp' | 'Email'
  recipientEmail: string
  recipientContactNumber: string
}

export const QuoteShare = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessfullySent, setIsSuccessfullySent] = useState(false)
  const [quoteUrl, setQuoteUrl] = useState('')
  const {getClientSideEnvironment} = getEnvironment()
  const environment = getClientSideEnvironment()

  const {gender, age, isSmoker, premium, payout} = useAppSelector(
    (state) => state.quote,
  )
  const user = useAppSelector((state) => state.user)

  const agentReferralCode = user?.agent?.referralCode
  const agentName = user?.agent?.agentName

  const validationSchema = Yup.object().shape({
    recipientFirstName: Yup.string().required(
      'Please enter the recipients first name',
    ),
    recipientLastName: Yup.string().required(
      'Please enter the recipients last name',
    ),
    sentBy: Yup.string().required('Please enter your name'),
    recipientEmail: Yup.string()
      .email('Please enter a valid email address')
      .when('deliveryMethod', {
        is: 'Email',
        then: Yup.string().required('Please enter your email address'),
      }),
    recipientContactNumber: Yup.string().when('deliveryMethod', {
      is: 'Whatsapp',
      then: Yup.string().required('Please enter your email address'),
    }),
  })

  const initialValues: FormValues = {
    recipientFirstName: '',
    recipientLastName: '',
    sentBy: agentName ? agentName : '',
    referralCode: agentReferralCode ? agentReferralCode : '',
    deliveryMethod: 'Whatsapp',
    recipientEmail: '',
    recipientContactNumber: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        const {data} = await axios.post('/api/quote-flyer/generate', {
          recipientFirstName: values.recipientFirstName,
          recipientLastName: values.recipientLastName,
          referrerFullName: values.sentBy,
          referralCode: values.referralCode,
          gender: gender === 'male' ? 'Male' : 'Female',
          smokerStatus: isSmoker ? 'Smoker' : 'Non-Smoker',
          age: age,
          benefitValue: `R${formatCurrency(payout)}`,
          premiumValue: `R${formatCurrency(premium)}`,
          cancerRelapseValue: `R${formatCurrency(
            calculatePercentageValue(CANCER_RELAPSE_PERCENTAGE, payout),
          )}`,
          cancerTestingValue: `R${formatCurrency(
            calculatePercentageValue(CANCER_TESTING_PERCENTAGE, payout),
          )}`,
          childBenefitValue: `R${formatCurrency(
            calculatePercentageValue(CHILD_CANCER_PERCENTAGE, payout),
          )}`,
        })
        setQuoteUrl(data.url)
        setIsSuccessfullySent(true)
        setIsLoading(false)
        // create event in CM.com if production
        // note: review environment is preview
        const event: ApiCMEvent = {
          FirstName: values.recipientFirstName,
          LastName: values.recipientLastName,
          ReferralCode: values.referralCode,
          Email: values.recipientEmail,
          PhoneNumber: values.recipientContactNumber,
          Gender: gender,
          SalesStatus: 'quoted',
          SmokerStatus: isSmoker ? 'smoker' : 'non-smoker',
          Age: Number(age),
          BenefitValue: payout,
          Premium: premium,
          QuoteSentBy: values.sentBy,
          QuoteFlyer: data.url,
          WhatsappConsent: values.recipientContactNumber ? 1 : 0,
          QuoteSend_Whatsapp: values.deliveryMethod === 'Whatsapp',
          QuoteSend_Email: values.deliveryMethod === 'Email',
        }
        if (environment === 'production') {
          // post to CM
          await axios.post('/api/cm/createEvent', event)
          // post message to slack
          await axios.post('/api/slack/lead', {
            recipientName: `${values.recipientFirstName} ${values.recipientLastName}`,
            sentBy: values.sentBy,
            email: values.recipientEmail,
            contactNumber: values.recipientContactNumber,
            referralCode: values.referralCode,
            quoteUrl: data.url,
          })
        }
      } catch (e) {
        setIsLoading(false)
        console.error(e)
      }
    },
  })

  const {contactNumberMask, nextContactNumber} = useContactNumberMask(
    formik.values.recipientContactNumber,
  )

  useEffect(() => {
    if (nextContactNumber) {
      formik.setFieldValue('recipientContactNumber', nextContactNumber)
    }
  }, [nextContactNumber])

  const submitForm = () => {
    formik.handleSubmit()
  }

  return (
    <Wrapper>
      {!isOpen && (
        <ShareQuoteButton onClick={() => setIsOpen(!isOpen)}>
          <ShareIcon />
          <span>Share Quote </span>
        </ShareQuoteButton>
      )}

      {isOpen && !isSuccessfullySent && (
        <FormWrapper>
          <ShareQuoteHeading>Share Quote</ShareQuoteHeading>
          <CloseButton onClick={() => setIsOpen(!isOpen)}>
            <CloseIcon size={16} />
          </CloseButton>

          <FormField>
            <TextInput
              placeholder=""
              label="Recipient First Name"
              onChange={(e) =>
                formik.setFieldValue('recipientFirstName', e.target.value)
              }
              error={
                !!(
                  formik.errors.recipientFirstName &&
                  formik.touched.recipientFirstName
                )
              }
              errorMessage={formik.errors.recipientFirstName}
              value={formik.values.recipientFirstName}
            />
          </FormField>
          <FormField>
            <TextInput
              placeholder=""
              label="Recipient Last Name"
              onChange={(e) =>
                formik.setFieldValue('recipientLastName', e.target.value)
              }
              error={
                !!(
                  formik.errors.recipientLastName &&
                  formik.touched.recipientLastName
                )
              }
              errorMessage={formik.errors.recipientLastName}
              value={formik.values.recipientLastName}
            />
          </FormField>
          <FormField>
            <TextInput
              placeholder=""
              label="Sent By (Your Name)"
              onChange={(e) => formik.setFieldValue('sentBy', e.target.value)}
              error={!!(formik.errors.sentBy && formik.touched.sentBy)}
              errorMessage={formik.errors.sentBy}
              value={formik.values.sentBy}
            />
          </FormField>
          <FormField>
            <TextInput
              placeholder="Optional"
              label="Referral Code"
              onChange={(e) =>
                formik.setFieldValue('referralCode', e.target.value)
              }
              value={formik.values.referralCode}
            />
          </FormField>
          <SegmentedControlContainer>
            <SegmentedControlLabel>Quote Delivery</SegmentedControlLabel>
            <SegmentedControlText>
              <span>Should this quote be sent via Whatsapp or Email?</span>
            </SegmentedControlText>
            <SegmentedControl
              segments={['Whatsapp', 'Email']}
              onChange={(value) =>
                formik.setFieldValue('deliveryMethod', value)
              }
              value={formik.values.deliveryMethod}
            />
          </SegmentedControlContainer>

          {formik.values.deliveryMethod === 'Email' && (
            <FormField>
              <TextInput
                placeholder=""
                label="Recipient Email Address"
                onChange={(e) =>
                  formik.setFieldValue('recipientEmail', e.target.value)
                }
                error={
                  !!(
                    formik.errors.recipientEmail &&
                    formik.touched.recipientEmail
                  )
                }
                errorMessage={formik.errors.recipientEmail}
                value={formik.values.recipientEmail}
              />
            </FormField>
          )}

          {formik.values.deliveryMethod === 'Whatsapp' && (
            <FormField>
              <TextInput
                placeholder=""
                label="Recipient Contact Number"
                numeric
                mask={contactNumberMask}
                onChange={(e) =>
                  formik.setFieldValue('recipientContactNumber', e.target.value)
                }
                error={
                  !!(
                    formik.errors.recipientContactNumber &&
                    formik.touched.recipientContactNumber
                  )
                }
                errorMessage={formik.errors.recipientContactNumber}
                value={formik.values.recipientContactNumber}
              />
            </FormField>
          )}

          <Button variant="secondary" onClick={submitForm} disabled={isLoading}>
            {isLoading ? 'Sending Quote...' : 'Send Quote'}
          </Button>

          <SampleLinkWrapper>
            <SampleLink href="/documents/QuoteFlyer_Filled.pdf" target="_blank">
              View Sample Quote
            </SampleLink>
          </SampleLinkWrapper>
        </FormWrapper>
      )}

      {isOpen && isSuccessfullySent && (
        <FormWrapper>
          <SuccessHeading>Quote Sent Successfully</SuccessHeading>
          <p>
            Thanks, {formik.values.sentBy}! We have sent a
            {formik.values.deliveryMethod === 'Email' ? 'n' : ''}{' '}
            {formik.values.deliveryMethod} to {formik.values.recipientFirstName}{' '}
            which contains a{' '}
            <QuoteLink href={quoteUrl} target="_blank">
              link to this quote
            </QuoteLink>
            .
          </p>
        </FormWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: var(--spacing-unit);
`

const ShareQuoteButton = styled.button`
  font-size: 0.875rem;
  background: none;
  cursor: pointer;
  outline: inherit;
  border: 0 none;
  color: var(--text-purple);
  display: flex;
  margin: 0 auto;

  span {
    margin-left: calc(var(--spacing-unit) / 3);
  }
`

const ShareQuoteHeading = styled.h2`
  margin-bottom: calc(var(--spacing-unit) * 2);
  font-size: 1.25rem;
`

const SuccessHeading = styled.h2`
  margin-bottom: 0;
  font-size: 1.25rem;
`

const FormWrapper = styled.div`
  border: 1px solid #d7d4f9;
  border-radius: 30px;
  padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
  position: relative;
`

const CloseButton = styled.button`
  background: none;
  cursor: pointer;
  outline: inherit;
  border: 0;
  padding: 0;
  color: var(--text-purple);
  background-color: var(--lavendar);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
`

const FormField = styled.div`
  margin-bottom: calc(var(--spacing-unit) * 3);
`

const SegmentedControlContainer = styled(FormField)`
  text-align: left;
  margin-bottom: calc(var(--spacing-unit) * 2);
`

const SegmentedControlLabel = styled.label`
  color: var(--text-purple);
  font-weight: var(--font-semibold);
  display: block;
  text-align: center;
`

const SegmentedControlText = styled.p`
  font-size: 12px;
  font-weight: var(--font-regular);
  text-align: center;
  margin: calc(var(--spacing-unit) / 2) 0 0 0;
  display: flex;
  justify-content: center;

  img {
    margin-left: 4px;
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }
`

const SampleLinkWrapper = styled.div`
  padding-top: var(--spacing-unit);
`

const SampleLink = styled.a`
  font-size: 12px;
`

const QuoteLink = styled.a`
  font-weight: var(--font-light);
  color: var(--text-purple);
`
