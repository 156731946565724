/**
 *
 * @param str - The input string to capitalize
 * @returns - The string with each word capitalized
 */
export function capitalizeEveryWord(str: string) {
  const words = str.split(' ')

  for (let i = 0; i < words.length; i++) {
    const lowerCaseWord = words[i].toLowerCase()
    words[i] = lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1)
  }

  return words.join(' ')
}
