import {useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import {useUser} from '@auth0/nextjs-auth0'

export const UserInfo = () => {
  const router = useRouter()
  const {user, isLoading} = useUser()
  const [timesClicked, setTimesClicked] = useState(0)

  useEffect(() => {
    if (timesClicked >= 10) {
      router.push('/api/auth/logout')
    }
  }, [timesClicked])

  const incrementClickForLogout = () => setTimesClicked(timesClicked + 1)

  if (user && !isLoading) {
    return <span onClick={incrementClickForLogout}>{user.email}</span>
  }

  return null
}
