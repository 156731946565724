import React from 'react'
import styled from 'styled-components'

type Props = {
  items: string[]
}

export const OrderedList: React.FC<Props> = ({items}) => {
  return (
    <Container>
      {items.map((item, index) => (
        <Item key={index}>
          <Number>{index + 1}</Number>
          <Text>{item}</Text>
        </Item>
      ))}
    </Container>
  )
}

const Container = styled.ol`
  width: 100%;
  list-style: none;
  margin: 0 0 calc(var(--spacing-unit) * 1.5) 0;
  padding: 0;

  @media (min-width: 768px) {
    margin-bottom: calc(var(--spacing-unit) * 3);
  }
`

const Item = styled.li`
  background-color: var(--lavendar);
  border-radius: 45px;
  min-height: 50px;
  display: grid;
  grid-template-columns: 42px auto;
  padding: calc(var(--spacing-unit) / 4);
  margin-top: calc(var(--spacing-unit) / 2);
`

const Number = styled.span`
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background: var(--light-purple);
  color: var(--white);
  font-weight: var(--font-bold);
  line-height: 42px;
  align-self: center;
`

const Text = styled.p`
  color: var(--purple);
  text-align: left;
  padding: calc(var(--spacing-unit) / 2);
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
`
