import React from 'react'
import styled from 'styled-components'

type Props = {
  align?: 'left' | 'center'
}

export const SummaryCard: React.FC<Props> = ({
  children,
  align = 'center',
  ...props
}) => {
  return (
    <Card align={align} {...props}>
      {children}
    </Card>
  )
}

const Card = styled.div<{align: 'left' | 'center'}>`
  background-color: var(--lavendar);
  border-radius: 32px;
  padding: calc(var(--spacing-unit) * 1.5);
  text-align: ${(props) => props.align};
  margin-bottom: calc(var(--spacing-unit) * 2);
`
