import moment from 'moment'
import {useRouter} from 'next/router'
import {useEffect} from 'react'
import {REF_CODE_QUERY_PARAM, TEST_ENV_QUERY_PARAM} from '../constants'
import {
  PersonalDetails,
  setDateOfBirth,
  setPersonalDetails,
  setReferralCode,
} from '../features/personal-information/personal-information-slice'
import {setGender} from '../features/quote/quote-slice'
import {Gender} from '../types'
import {useAppDispatch} from './useAppDispatch'

/**
 * Generates a random date of birth in the format `DD/MM/yyyy`
 * @param start The start day in the range
 * @param end The end day in the range
 * @returns The generated date of birth
 */
const generateRandomDob = (start: Date, end: Date): string => {
  const randomDob = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime()),
  )

  return moment(randomDob).format('DD/MM/yyyy')
}

/**
 * Generates a random 10-digit phone number
 * @returns
 */
const generateRandomContactNumber = (): string => {
  const chars = '1234567890'
  let contactNumber = ''
  for (let ii = 0; ii < 9; ii++) {
    contactNumber += chars[Math.floor(Math.random() * chars.length)]
  }
  return `${contactNumber}`
}

/**
 * Generates a random South African ID number
 * @param dateOfBirth The date of birth in format `DD/MM/yyyy`
 * @param gender The gender `male` | `female`
 * @returns A random ID number
 */
const generateRandomIdNumber = (
  dateOfBirth: string,
  gender: Gender,
): string => {
  const splitDob = dateOfBirth.split('/')
  const year = splitDob[2].substring(2, 4)
  const month = splitDob[1]
  const day = splitDob[0]
  const formattedDob = `${year}${month}${day}`

  if (gender === 'male') return `${formattedDob}5800085`

  return `${formattedDob}4800086`
}

const getRandomUser = async () => {
  const res = await fetch('https://randomuser.me/api/')
  return res.json()
}

/**
 * Used to prepopulate the redux store with generated values if test environment is enabled
 */
export function usePrepopulateStore() {
  const router = useRouter()
  const dispatch = useAppDispatch()

  const isAutomatedTestEnv = router.query[TEST_ENV_QUERY_PARAM] === '1'

  const refCodeQueryParam = router.query[REF_CODE_QUERY_PARAM] as string

  useEffect(() => {
    // prepopulate store with referral code
    if (refCodeQueryParam) {
      dispatch(setReferralCode(refCodeQueryParam))
    }
    // prepopulate store with random user
    if (isAutomatedTestEnv) {
      getRandomUser().then((user) => {
        const randomUser = user?.results[0]

        // gender
        const gender = randomUser.gender
        dispatch(setGender(randomUser.gender as Gender))

        // date of birth
        const randomDOB = generateRandomDob(
          new Date(1970, 0, 1),
          new Date(2002, 0, 1),
        )
        dispatch(setDateOfBirth(randomDOB))

        // personal details
        const personalDetails: PersonalDetails = {
          firstName: randomUser.name.first,
          lastName: randomUser.name.last,
          idNumber: generateRandomIdNumber(randomDOB, gender),
          emailAddress: randomUser.email,
          contactNumber: generateRandomContactNumber(),
          address: 'Tester Road, Cape Town, South Africa',
          postalCode: '1234',
          city: 'Test Town',
          popiaConsent: true,
          whatsappConsent: true,
          referralCode: '',
          bankName: 'Standard Bank',
          bankAccountNumber: '98766238338',
          bankAccountType: 'Cheque',
        }
        dispatch(setPersonalDetails(personalDetails))
      })
    }
  }, [isAutomatedTestEnv, refCodeQueryParam])
}
