// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import packageInfo from './package.json'
import LogRocket from 'logrocket'
import {getEnvironment} from './src/utils'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const {getServerSideEnvironment} = getEnvironment()
const environment = getServerSideEnvironment()

if (environment !== 'development') {
  // sentry
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://9e4765ec448440939abe1df49712727d@o1036002.ingest.sentry.io/6033291',
    tracesSampleRate: 1.0,
    environment: environment,
    release: packageInfo.version,
  })
  // logrocket
  LogRocket.init('1x6vir/sales-funnel-hzaz3')
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL)
    })
  })
}
