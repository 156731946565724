import {useRouter} from 'next/router'
import {TEST_ENV_QUERY_PARAM} from '../constants'

export function useNavigation(): any {
  const router = useRouter()

  const navigate = (e: React.MouseEvent, route: string) => {
    e && e.preventDefault()

    // if we are running in the automated test environment we want to preserve the query params
    if (router.query[TEST_ENV_QUERY_PARAM] === '1') {
      router.push(`${route}?${TEST_ENV_QUERY_PARAM}=1`)
    }

    router.push(route)
  }

  return navigate
}
