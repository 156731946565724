import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'

export const PartnerLogos: React.FC = () => {
  return (
    <PeaceOfMindContainer>
      <PeaceOfMindHeading>
        Quality Partners. Total Peace of Mind.
      </PeaceOfMindHeading>
      <Logos>
        <InnerLogoRow>
          <Image
            src="/images/rga-logo.png"
            alt="RGA logo"
            width={70}
            height={28}
          />
          <Image
            src="/images/c2s-logo.png"
            alt="C2S logo"
            width={100}
            height={22}
          />
          <Image
            src="/images/guardrisk-logo.png"
            alt="Guardrisk logo"
            width={128}
            height={22}
          />
        </InnerLogoRow>
      </Logos>
      <PeaceOfMindText>
        Policies Reinsured by <span>RGA</span> (A+ Financial Strength Rating)
        <br />
        Policy Administration technology is provided by <span>Click2Sure</span>
        <br />
        Life Licence Provider is <span>Guardrisk</span>
        <br />
        Administrator is <span>Capital Legacy Solutions</span>
      </PeaceOfMindText>
      <Image
        src="/images/fcsa-logo.png"
        alt="FCSA logo"
        width={93}
        height={25}
      />
      <PeaceOfMindText>
        Our insurance operation is regulated by <span>FSCA</span> - the
        Financial Services Conduct Authority of South Africa.
      </PeaceOfMindText>
    </PeaceOfMindContainer>
  )
}

const PeaceOfMindContainer = styled.section`
  text-align: center;
  padding: calc(var(--spacing-unit) * 1.8) var(--spacing-unit) 0;

  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const PeaceOfMindHeading = styled.h4`
  font-size: 1rem;
  font-weight: var(--font-regular);
  color: var(--black);
`

const PeaceOfMindText = styled.p`
  font-size: 0.688rem;
  color: var(--black);
  margin-bottom: var(--spacing-unit);

  span {
    font-weight: var(--font-semibold);
  }
`

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  margin: 0 auto;
  justify-content: center;

  @media (min-width: 992px) {
    flex-direction: row;
    width: 510px;
    margin-bottom: var(--spacing-unit);
  }
`

const InnerLogoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: calc(var(--spacing-unit) / 2);

  @media (min-width: 992px) {
    flex-direction: row;
    width: 75%;
  }
`
