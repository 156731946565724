import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BankAccountType} from '../../types'

export interface PersonalDetails {
  firstName: string
  lastName: string
  idNumber: string
  emailAddress: string
  contactNumber: string
  address: string
  postalCode: string
  city: string
  popiaConsent: boolean
  whatsappConsent: boolean
  referralCode: string
  bankName: string
  bankAccountType: BankAccountType | undefined
  bankAccountNumber: string
}
interface PersonalInformation {
  dateOfBirth: string
  personalDetails: PersonalDetails
}

const initialState: PersonalInformation = {
  dateOfBirth: undefined,
  personalDetails: {
    firstName: '',
    lastName: '',
    idNumber: '',
    emailAddress: '',
    contactNumber: '',
    address: '',
    postalCode: '',
    city: '',
    popiaConsent: true,
    whatsappConsent: true,
    referralCode: '',
    bankName: '',
    bankAccountType: undefined,
    bankAccountNumber: '',
  },
}

const personalInformationSlice = createSlice({
  name: 'personalInformation',
  initialState,
  reducers: {
    setDateOfBirth(state, action: PayloadAction<string>) {
      state.dateOfBirth = action.payload
    },
    setPersonalDetails(state, action: PayloadAction<PersonalDetails>) {
      state.personalDetails = action.payload
    },
    setReferralCode(state, action: PayloadAction<string>) {
      state.personalDetails.referralCode = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setDateOfBirth,
  setPersonalDetails,
  setReferralCode,
  reset,
} = personalInformationSlice.actions
export default personalInformationSlice.reducer
