import React, {useState} from 'react'
import styled from 'styled-components'

type Props = {
  value: string
  onChange: (val: string) => void
  segments: string[]
  error?: boolean
  errorMessage?: string
}

export const SegmentedControl: React.FC<Props> = ({
  value,
  onChange,
  segments,
  error,
  errorMessage,
}) => {
  const [selectedSegment, setSelectedSegment] = useState<string>(value)

  return (
    <Wrapper>
      <RadioContainer>
        {segments.map((s) => {
          const isSelected = selectedSegment === s
          return (
            <Radio checked={isSelected} key={s}>
              <HiddenRadio
                value={s}
                checked={isSelected}
                name={s}
                onChange={() => {
                  setSelectedSegment(s)
                  onChange(s)
                }}
                data-testid={`hidden-radio-${s}`}
              />
              <SegmentLabel isSelected={isSelected}>
                <span>{s}</span>
              </SegmentLabel>
            </Radio>
          )
        })}
      </RadioContainer>

      {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: var(--spacing-unit) 0;
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lavendar);
  border-radius: 100px;
  height: 41px;
  width: 100%;

  @media (min-width: 768px) {
    height: 50px;
  }
`

const Radio = styled.label<{checked: boolean}>`
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 41px;
  border-radius: 74px;
  background-color: ${(props) =>
    props.checked ? 'var(--text-purple)' : 'transparent'};

  @media (min-width: 768px) {
    height: 50px;
  }
`

const HiddenRadio = styled.input.attrs({type: 'radio'})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const SegmentLabel = styled.span<{isSelected: boolean}>`
  color: ${(props) =>
    props.isSelected ? 'var(--white)' : 'var(--text-purple)'};
  font-weight: var(--font-bold);
`

const ErrorMessage = styled.span`
  font-size: 12px;
  padding-left: 12px;
  color: var(--red);
`
