import moment from 'moment'

/**
 * Calculates the age from a specified date
 * @param day The day
 * @param month The month
 * @param year The year
 * @returns The age
 */
export function calculateAgeFromDate(
  day: string,
  month: string,
  year: string,
): number {
  const momentDateOfBirth = moment(`${year}-${month}-${day}`)
  const age = momentDateOfBirth.diff(moment(), 'years') * -1
  return age
}
