import {useContext} from 'react'
import {ProgressContext} from '../contexts'

export function useProgress(): any {
  const {progress, setProgress} = useContext(ProgressContext)
  const NUM_SCREENS = 13
  const PROGRESS_INCREMENT = 100 / NUM_SCREENS

  function incrementProgress() {
    if (progress > 0) {
      setProgress(progress + PROGRESS_INCREMENT)
    } else {
      setProgress(PROGRESS_INCREMENT)
    }
  }

  function decrementProgress() {
    setProgress(progress - PROGRESS_INCREMENT)
  }

  return {
    incrementProgress,
    decrementProgress,
  }
}
