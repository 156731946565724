/**
 * Calculates the payout amount for each step number
 * @param step The current step
 * @param basePayout The base payout
 * @returns The payout
 */
export function calculatePayoutFromStepNumber(
  step: number,
  basePayout: number,
): number {
  return basePayout + step * 100000
}
