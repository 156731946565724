import React from 'react'
import styled from 'styled-components'
import {Header} from '.'
import {ProgressProvider} from '../contexts'

export const Layout: React.FC = (props) => {
  const {children} = props

  // append whatsapp for business cm.com script
  // this was a workaround because of : namespaces
  if (typeof document !== 'undefined') {
    const scriptStr =
      '<script src="https://apps.elfsight.com/p/platform.js" defer></script>'
    const frag = document.createRange().createContextualFragment(scriptStr)
    document.body.appendChild(frag)
  }

  return (
    <ProgressProvider>
      <Wrapper>
        <Header />
        <Main>{children}</Main>
        <WhatsApp>
          <div className="elfsight-app-268e0b24-893a-47a4-9501-13e33070449e"></div>
        </WhatsApp>
      </Wrapper>
    </ProgressProvider>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    min-height: -webkit-fill-available;
  }
`

const Main = styled.main`
  display: flex;
  flex: 1;

  @media (min-width: 992px) {
    justify-content: center;
    align-items: center;
  }
`

const WhatsApp = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 768px) {
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
`
