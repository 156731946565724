import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import ReactSlider from 'react-slider'
import {calculatePayoutFromStepNumber, formatCurrency} from '../utils'
import {ApiPremiumResponseV2} from '../types'

type Props = {
  basePayout: number
  initialPayout: number
  premiumResponse: ApiPremiumResponseV2
  onChange: ({payout, premium}) => void
  sliderMaxValue: number
}

export const CoverSlider: React.FC<Props> = ({
  basePayout,
  premiumResponse,
  onChange,
  initialPayout,
  sliderMaxValue,
}) => {
  const intialStepFromBase = parseInt(basePayout.toString()[0])
  const [sliderValue, setSliderValue] = useState<number>(
    Math.round(initialPayout * 0.00001 - intialStepFromBase),
  )
  const [premium, setPremium] = useState(0)
  const [payout, setPayout] = useState(0)

  useEffect(() => {
    const selectedPayout = calculatePayoutFromStepNumber(
      sliderValue,
      basePayout,
    )
    const premiumForSelectedPayout = premiumResponse.rates.find(
      (i) => i.payout === selectedPayout,
    ).premium
    const roundedPremium = Math.round(premiumForSelectedPayout)
    setPremium(roundedPremium)
    setPayout(selectedPayout)
    // run onChange
    onChange({
      premium: roundedPremium,
      payout: selectedPayout,
    })
  }, [sliderValue])

  return (
    <Container>
      <CoverCard>
        <Content>
          <Values>
            <Left>
              <Heading>R{formatCurrency(payout)}</Heading>
              <SubHeading>Main Benefit</SubHeading>
            </Left>
            <Right>
              <Heading>R{formatCurrency(premium)}</Heading>
              <SubHeading>Monthly Premium</SubHeading>
            </Right>
          </Values>
          <AdjustHeading>Slide to Edit</AdjustHeading>
          <Slider>
            <ReactSlider
              className="slider"
              thumbClassName="thumb"
              trackClassName="track"
              marks
              min={0}
              max={sliderMaxValue}
              markClassName="mark"
              renderThumb={(props) => <div {...props} />}
              onChange={(v: number) => {
                setSliderValue(v)
              }}
              value={sliderValue}
            />
          </Slider>
          <Values>
            <Value>R{formatCurrency(basePayout)} min</Value>
            <Value>R2,000,000 max</Value>
          </Values>
        </Content>
      </CoverCard>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
`

const CoverCard = styled.div`
  width: 100%;
  background: var(--lavendar);
  border-radius: 6px 6px 30px 30px;
  margin-top: calc(var(--spacing-unit) / 2);

  @media (min-width: 768px) {
    margin-top: calc(var(--spacing-unit) * 2);
  }
`

const Content = styled.section`
  padding: calc(var(--spacing-unit) * 1.5) var(--spacing-unit);

  @media (min-width: 768px) {
    padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 1.5)
      calc(var(--spacing-unit) * 2.5);
  }
`

const Values = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Left = styled.div`
  text-align: left;
`

const Right = styled.div`
  text-align: right;
`

const Heading = styled.p`
  font-weight: var(--font-bold);
  font-size: 1.25rem;
  margin-bottom: calc(var(--spacing-unit) / 3);
`

const SubHeading = styled.p`
  font-size: 0.75rem;
  margin: 0;
  color: var(--black);

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
`

const AdjustHeading = styled.p`
  font-size: 1rem;
  margin: var(--spacing-unit) 0 0 0;
  color: var(--orange);
`

const Slider = styled.div`
  height: 24px;
  display: flex;
  flex-direction: column;
  margin: var(--spacing-unit) 0;

  @media (min-width: 768px) {
    height: 32px;
  }

  .slider {
    display: flex;
    flex: 1;
  }

  .track {
    height: 4px;
    border-radius: 4px;
    background-color: var(--purple);
    top: 10px;

    @media (min-width: 768px) {
      top: 15px;
    }

    &.track-0 {
      opacity: 1;
    }

    &.track-1 {
      opacity: 0.5;
    }
  }

  .mark {
    background-color: var(--purple);
    opacity: 0.2;
    border-radius: 4px;
    top: 10px;
    width: 4px;
    height: 4px;

    @media (min-width: 768px) {
      top: 15px;
    }
  }

  .thumb {
    width: 24px;
    height: 24px;
    background-color: var(--orange);
    border-radius: 24px;
    outline: 0;
    color: var(--purple);

    @media (min-width: 768px) {
      height: 32px;
      width: 32px;
    }
  }
`

const Value = styled.span`
  font-size: 0.75rem;
  color: var(--black);
`
