// library imports
import {configureStore} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import LogRocket from 'logrocket'
// reducer imports
import quoteReducer from './features/quote/quote-slice'
import personalInformationReducer from './features/personal-information/personal-information-slice'
import medicalQuestionsReducer from './features/medical-questions/medical-questions-slice'
import userReducer from './features/user/user-slice'

// redux-persist config
const quote_persistConfig = {
  key: 'quote',
  version: 1,
  storage,
}
const personalInformation_persistConfig = {
  key: 'personalInformation',
  version: 1,
  storage,
}

// persisted reducers
const persistedQuoteReducer = persistReducer(quote_persistConfig, quoteReducer)
const persistedPersonalInformationReducer = persistReducer(
  personalInformation_persistConfig,
  personalInformationReducer,
)

// store config
export const store = configureStore({
  reducer: {
    quote: persistedQuoteReducer,
    personalInformation: persistedPersonalInformationReducer,
    medicalQuestions: medicalQuestionsReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(LogRocket.reduxMiddleware()),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
