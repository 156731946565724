import React from 'react'
import styled from 'styled-components'

type Props = {
  label: string
  id: string
  name: string
  value: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  checked: boolean
  error?: boolean
  renderIcon?: () => void
}

export const RadioButton: React.FC<Props> = (props) => {
  const {
    label,
    id,
    name,
    value,
    onChange,
    checked,
    error = false,
    renderIcon,
  } = props

  return (
    <Radio>
      {renderIcon && renderIcon()}
      <RadioLabel>{label}</RadioLabel>
      <HiddenRadio
        id={id}
        name={name}
        value={value}
        onChange={(e: React.FormEvent<HTMLInputElement>) => onChange(e)}
        checked={checked}
      />
      <StyledRadio error={error} isChecked={checked}>
        {checked && <Bullet />}
      </StyledRadio>
    </Radio>
  )
}

const Radio = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: var(--spacing-unit) 0;
  line-height: 18px;
`

const RadioLabel = styled.span`
  text-align: left;
  color: var(--purple);
`

const HiddenRadio = styled.input.attrs({type: 'radio'})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const StyledRadio = styled.div<{isChecked: boolean; error: boolean}>`
  width: 20px;
  height: 20px;
  border: 2px solid
    ${(props) =>
      props.error
        ? 'var(--red)'
        : props.isChecked
        ? 'var(--purple)'
        : '#7B70EA'};
  border-radius: 18px;
  margin-right: var(--spacing-unit);
  display: flex;
  flex: 0 0 20px;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s ease-in-out;
`

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--purple);
`
