import {useEffect, useState} from 'react'

const DEFAULT_MASK = '+27 99 999 9999'

/**
 * Used to return the mask for the contact number
 * @param contactNumber - The contact number
 * @returns - The mask and the new masked contact number
 */
export function useContactNumberMask(contactNumber: string) {
  const [nextContactNumber, setNextContactNumber] = useState('')
  const [contactNumberMask, setContactNumberMask] = useState(DEFAULT_MASK)

  useEffect(() => {
    const joinedContactNumber = contactNumber.split(' ').join('')
    if (joinedContactNumber.charAt(3) === '0') {
      setContactNumberMask('+27 999 999 9999')
      if (joinedContactNumber.length === 13) {
        const countryCode = joinedContactNumber.slice(0, 3)
        const restOfNumber = joinedContactNumber.slice(
          4,
          joinedContactNumber.length,
        )
        setNextContactNumber(`${countryCode}${restOfNumber}`)
        // formik.setFieldValue('contactNumber', `${countryCode}${restOfNumber}`)
        setContactNumberMask(DEFAULT_MASK)
      }
    } else {
      setContactNumberMask(DEFAULT_MASK)
      if (joinedContactNumber.length === 12) {
        setNextContactNumber(joinedContactNumber)
        // formik.setFieldValue('contactNumber', joinedContactNumber)
      }
    }
  }, [contactNumber])

  return {
    contactNumberMask,
    nextContactNumber,
  }
}
