import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'

type ColorProps = 'purple' | 'orange'

type Props = {
  placeholder: string
  label: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  mask?: string
  autocomplete?: string
  numeric?: boolean
  error?: boolean
  errorMessage?: string
  value?: string
  color?: ColorProps
}

export const TextInput: React.FC<Props> = (props) => {
  const {
    placeholder,
    label,
    onChange,
    mask,
    autocomplete,
    numeric,
    error,
    errorMessage,
    value,
    color = 'purple',
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const [isActivated, setIsActivated] = useState(false)

  useEffect(() => {
    if (value) {
      setIsFocused(true)
      setIsActivated(true)
    }
  }, [value])

  return (
    <Container {...props}>
      <Label
        isFocused={isFocused}
        isActivated={isActivated}
        error={error}
        color={color}
      >
        {label}
      </Label>
      <InputField
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={isFocused ? placeholder : ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          ;() => onChange(e)
          e.currentTarget.value ? setIsActivated(true) : setIsActivated(false)
        }}
        mask={mask}
        maskChar=""
        autoComplete={autocomplete}
        pattern={numeric ? '[0-9]*' : ''}
        error={error}
        value={value}
        color={color}
        data-error={error}
      />
      {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: relative;
  text-align: left;
`

const ErrorMessage = styled.span`
  font-size: 12px;
  padding-left: 12px;
  color: var(--red);
`

const Label = styled.label<{
  isFocused: boolean
  isActivated: boolean
  error: boolean
  color: ColorProps
}>`
  position: absolute;
  left: 12px;
  top: ${(props) => (props.isFocused || props.isActivated ? '-18px' : '8px')};
  font-size: ${(props) =>
    props.isFocused || props.isActivated ? '0.75rem' : '1rem'};
  color: ${(props) =>
    props.error
      ? 'var(--red)'
      : props.isFocused || props.isActivated
      ? `var(--${props.color})`
      : `var(${props.color === 'orange' ? '--orange' : '--gray'})`};
  font-weight: ${(props) =>
    props.isFocused || props.isActivated
      ? 'var(--font-semibold)'
      : 'var(--font-regular)'};
  line-height: 21px;
  // animation
  animation-name: ${(props) =>
    (props.isFocused || props.isActivated) && 'animateIn'};
  animation-duration: 0.25s;
  ${(props) => (props.isFocused || props.isActivated) && animateIn}
`

const animateIn = `
  @keyframes animateIn {
    from {
      top: 8px;
      font-size: 1rem;
    }
    to {
      top: -18px;
      font-size: 0.75rem;
    }
  }
`

const InputField = styled(InputMask)<{error: boolean; color: ColorProps}>`
  border: 0 none;
  border-bottom: 1px solid
    ${(props) =>
      props.error
        ? 'var(--red)'
        : props.color === 'orange'
        ? 'var(--orange)'
        : 'var(--text-purple)'};
  border-radius: 0;
  background-color: transparent;
  outline: 0;
  padding: calc(var(--spacing-unit) / 2) 12px;
  width: 100%;
  font-size: 1rem;
  font-family: var(--font-family);
  color: var(--dark-purple);
  font-weight: var(--font-regular);
  line-height: 21px;
  position: relative;
`
