import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import {useMediaQuery} from 'react-responsive'
import {FormikProps} from 'formik'

type Props = {
  formik: FormikProps<any>
  dateOfBirth: string
}

export const DateOfBirthInput: React.FC<Props> = (props) => {
  const {formik, dateOfBirth} = props

  const isDesktop = useMediaQuery({query: '(min-width: 992px)'})
  const [currentInput, setCuttentInput] = useState(0)
  const [dayError, setDayError] = useState(false)
  const [monthError, setMonthError] = useState(false)

  // autofocus logic
  useEffect(() => {
    if (!dateOfBirth) {
      const inputElements = document.getElementsByTagName('input')
      if (isDesktop) {
        inputElements[currentInput].focus()
      } else {
        if (currentInput > 0) {
          inputElements[currentInput].focus()
        }
      }
    }
  }, [currentInput])

  return (
    <Container>
      <InputContainer>
        <Label>Day</Label>
        <DateInput
          mask="99"
          maskChar=""
          placeholder="DD"
          onChange={(e) => {
            const {value} = e.target
            formik.setFieldValue('day', value)
            const hasError = Number(value) > 31
            setDayError(hasError)
            if (value.length === 2 && !hasError) {
              setCuttentInput(1)
            }
          }}
          onBlur={(e) => {
            const {value} = e.target
            if (value.length === 1 && Number(value) > 0) {
              formik.setFieldValue('day', `0${value}`)
            }
          }}
          error={dayError}
          pattern={'[0-9]*'}
          value={formik.values.day}
        />
      </InputContainer>
      <InputContainer>
        <Label>Month</Label>
        <DateInput
          mask="99"
          maskChar=""
          placeholder="MM"
          onChange={(e) => {
            const {value} = e.target
            formik.setFieldValue('month', value)
            const hasError = Number(value) > 12
            setMonthError(hasError)
            if (value.length === 2 && !hasError) {
              setCuttentInput(2)
            }
          }}
          onBlur={(e) => {
            const {value} = e.target
            if (value.length === 1 && Number(value) > 0) {
              formik.setFieldValue('month', `0${value}`)
            }
          }}
          error={monthError}
          pattern={'[0-9]*'}
          value={formik.values.month}
        />
      </InputContainer>
      <InputContainer>
        <Label>Year</Label>
        <DateInput
          mask="9999"
          maskChar=""
          placeholder="YYYY"
          onChange={(e) => {
            const {value} = e.target
            formik.setFieldValue('year', value)
          }}
          pattern={'[0-9]*'}
          value={formik.values.year}
        />
      </InputContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const InputContainer = styled.div`
  padding: 0 calc(var(--spacing-unit) / 4);
  text-align: left;
`

const Label = styled.label`
  font-weight: var(--font-semibold);
  font-size: 0.75rem;
`

const DateInput = styled(InputMask)<{error: boolean}>`
  outline: 0;
  padding: 0;
  border: 1px solid ${(props) => (props.error ? 'var(--red)' : '#c8c8c8')};
  color: var(--purple);
  font-weight: var(--font-regular);
  font-family: var(--font-family);
  font-size: 1rem;
  width: 100%;
  background: var(--white);
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  margin-top: calc(var(--spacing-unit) / 4);
  border-radius: 8px;
  appearance: none;

  &:focus {
    border-color: ${(props) => (props.error ? 'var(--red)' : 'var(--orange)')};
  }
`
