import {STAGING_HOST} from '../constants'

type VercelEnv = 'production' | 'preview' | 'development'
type NodeEnv = 'production' | 'development'

/**
 * Returns a function to get either the client side or server side environment
 * @returns `getClientSideEnvironment` `getServerSideEnvironment`
 */
export function getEnvironment() {
  /**
   * Gets the current client side environment
   * @returns The current environment
   */
  function getClientSideEnvironment(): VercelEnv {
    const VERCEL_ENV = (process.env.VERCEL_ENV ||
      process.env.NEXT_PUBLIC_VERCEL_ENV) as VercelEnv

    const hostname =
      typeof window !== 'undefined' && window.location.hostname
        ? window.location.hostname
        : ''

    if (hostname === STAGING_HOST) return 'preview'

    return VERCEL_ENV
  }

  /**
   * Gets the current server side environment
   * @returns The current environment
   */
  function getServerSideEnvironment(): NodeEnv {
    const NODE_ENV = (process.env.NODE_ENV ||
      process.env.NEXT_PUBLIC_NODE_ENV) as NodeEnv

    return NODE_ENV
  }

  return {
    getClientSideEnvironment,
    getServerSideEnvironment,
  }
}
