import React from 'react'
import Select from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styled from 'styled-components'

type SelectValues = {
  value: string
  label: string
  __isNew__?: boolean
}

type SelectOptions = Omit<SelectValues, '__isNew'>

type CommonProps = {
  isAsyncSelect: boolean
  onChange: (value: SelectValues) => void
  placeholder: string
  error?: boolean
  errorMessage?: string
  value?: SelectValues
}

type SelectProps = {
  options?: SelectOptions[]
  loadOptions?: never
  onClear?: never
}

type AsyncSelectProps = {
  options?: never
  loadOptions?: any
  onClear?: () => void
}

type ConditionalProps = SelectProps | AsyncSelectProps

type Props = CommonProps & ConditionalProps

const ReactSelect: React.FC<Props> = ({
  isAsyncSelect,
  onChange,
  onClear,
  loadOptions,
  options,
  placeholder,
  error,
  errorMessage,
  value,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '0 none',
      borderBottom: `1px solid ${error ? 'var(--red)' : 'var(--text-purple)'}`,
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 0,
      '&:hover': {
        borderColor: 'var(--purple)',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: `${error ? 'var(--red)' : 'var(--gray)'}`,
      marginLeft: 4,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--dark-purple)',
      opacity: 1,
      marginLeft: 4,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => {
      const defaultStyles = {
        ...provided,
        cursor: 'pointer',
        color: state.data.__isNew__ ? 'var(--orange)' : 'var(--dark-purple)',
        textAlign: 'left',
      }
      const selectedStyles = {
        color: state.data.__isNew__ ? 'var(--orange)' : 'var(--text-purple)',
        backgroundColor: 'var(--lavendar)',
      }
      if (state.isSelected || state.isFocused) {
        return {...defaultStyles, ...selectedStyles}
      }
      return {
        ...defaultStyles,
        '&:hover': {
          ...selectedStyles,
        },
      }
    },
  }

  return (
    <Container>
      {isAsyncSelect ? (
        <AsyncCreatableSelect
          onChange={(selectedOption, triggeredAction) => {
            if (triggeredAction.action === 'clear') {
              onClear()
            } else {
              onChange(selectedOption)
            }
          }}
          cacheOptions
          loadOptions={loadOptions}
          styles={customStyles}
          noOptionsMessage={() => 'Type to search'}
          autocomplete="off"
          placeholder={placeholder}
          defaultValue={value}
          isClearable={true}
        />
      ) : (
        <Select
          styles={customStyles}
          options={options}
          placeholder={placeholder}
          onChange={(option: SelectOptions) => onChange(option)}
          defaultValue={value}
        />
      )}
      {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}

export {ReactSelect as Select}

const Container = styled.div`
  width: 100%;
  position: relative;
  text-align: left;
`

const ErrorMessage = styled.span`
  font-size: 12px;
  padding-left: 12px;
  color: var(--red);
`
