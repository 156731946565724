import {useEffect} from 'react'
import {AppProps} from 'next/app'
import Head from 'next/head'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {UserProvider} from '@auth0/nextjs-auth0'
import {persistor, store} from '../store'
import {Layout} from '../components'
import {GlobalStyles} from '../theme/globalStyles'
import {hotjar} from 'react-hotjar'
import {getEnvironment} from '../utils'

export default function App({Component, pageProps}: AppProps): JSX.Element {
  const {getServerSideEnvironment} = getEnvironment()
  const environment = getServerSideEnvironment()

  useEffect(() => {
    // init hotjar
    environment === 'production' && hotjar.initialize(2668044, 6)
  }, [])

  return (
    <>
      <Head>
        <title>Aynjil - Get Covered</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <GlobalStyles />
      <UserProvider>
        <Layout>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Component {...pageProps} />
            </PersistGate>
          </Provider>
        </Layout>
      </UserProvider>
    </>
  )
}
