export const STAGING_HOST = 'sales-funnel-staging.aynjil.com'
export const LOCAL_HOST = 'localhost'
export const TEST_ENV_QUERY_PARAM = 'test_env'
export const REF_CODE_QUERY_PARAM = 'refcode'

export const CANCER_RELAPSE_PERCENTAGE = 25
export const BREAST_RECONSTRUCTIVE_SURGERY_PERCENTAGE = 10
export const CANCER_TESTING_PERCENTAGE = 10
export const CHILD_CANCER_PERCENTAGE = 10

export const BASE_PAYOUT_500K = 500000
export const BASE_PAYOUT_300K = 300000
export const BASE_PAYOUT_100K = 100000
