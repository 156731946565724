import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Agent} from '../../types'

interface UserState {
  agent: Agent
}

const initialState: UserState = {
  agent: undefined,
}

/**
 * This user of state is used to store all data related to a user (agent)
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Agent>) {
      state.agent = action.payload
    },
  },
})

export const {setUser} = userSlice.actions
export default userSlice.reducer
