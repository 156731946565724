import React from 'react'
import styled from 'styled-components'
import {BackButton} from './backButton'
import {Button} from './button'

type Props = {
  buttonText?: string
  buttonDisabled?: boolean
  hideFooter?: boolean
  onNextClick?: (e: React.MouseEvent) => void
  showHorizontalPadding?: boolean
  fixedHeight?: boolean
}

export const LayoutCard: React.FC<Props> = (props) => {
  const {
    children,
    buttonText = 'Next',
    hideFooter = false,
    showHorizontalPadding = true,
    onNextClick,
    fixedHeight = true,
    buttonDisabled = false,
  } = props

  return (
    <Wrapper isFixedHeight={fixedHeight} {...props}>
      <Inner showHorizontalPadding={showHorizontalPadding}>{children}</Inner>
      {!hideFooter && (
        <Footer>
          <BackButtonWrapper>
            <BackButton />
          </BackButtonWrapper>
          <NextButton disabled={buttonDisabled} onClick={onNextClick}>
            {buttonText}
          </NextButton>
        </Footer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.article<{isFixedHeight: boolean}>`
  background-color: var(--white);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 40px 40px;

  @media (min-width: 992px) {
    height: ${(props) => (props.isFixedHeight ? '561px' : 'auto')};
    max-width: 700px;
    justify-content: center;
  }
`

const Inner = styled.div<{showHorizontalPadding: boolean}>`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: calc(var(--spacing-unit) * 2);
  padding-bottom: var(--spacing-unit);
  padding-left: ${(props) =>
    props.showHorizontalPadding ? 'calc(var(--spacing-unit) * 1.5)' : '0'};
  padding-right: ${(props) =>
    props.showHorizontalPadding ? 'calc(var(--spacing-unit) * 1.5)' : '0'};
  max-width: 600px;

  @media (min-width: 992px) {
    flex: 1;
    max-width: 700px;
    width: 100%;
    justify-content: flex-start;
    padding: calc(var(--spacing-unit) * 3.5) 100px 0;
  }
`

const Footer = styled.div`
  width: 100%;
  padding: var(--spacing-unit);
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;

  .back {
    margin-right: 16px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 0 60px;
    margin: 0 calc(var(--spacing-unit) * 7) 0;
  }

  @media (min-width: 992px) {
    margin-right: 0;
    max-width: 100%;
    padding: calc(var(--spacing-unit) * 2.5);
  }
`

const NextButton = styled(Button)`
  @media (min-width: 992px) {
    max-width: 510px;
  }
`

const BackButtonWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`
