import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ApiPremiumResponseV2, Gender, SmokerStatus} from '../../types'

interface QuoteState {
  gender: Gender
  age: string
  smokerStatus: SmokerStatus
  isSmoker: boolean
  payout: number
  premium: number
  premiumResponse: ApiPremiumResponseV2
}

const initialState: QuoteState = {
  gender: 'male',
  age: undefined,
  smokerStatus: undefined,
  isSmoker: undefined,
  payout: undefined,
  premium: undefined,
  premiumResponse: undefined,
}

/**
 * This slice of state is used to store all data related to obtaining a quote
 */
const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setGender(state, action: PayloadAction<Gender>) {
      state.gender = action.payload
    },
    setAge(state, action: PayloadAction<string>) {
      state.age = action.payload
    },
    setSmokerStatus(state, action: PayloadAction<SmokerStatus>) {
      state.smokerStatus = action.payload
      state.isSmoker = action.payload === 'smoker'
    },
    setPayoutAndPremium(
      state,
      action: PayloadAction<{
        payout: number
        premium: number
      }>,
    ) {
      state.payout = action.payload.payout
      state.premium = action.payload.premium
    },
    setPremiumResponse(state, action: PayloadAction<ApiPremiumResponseV2>) {
      state.premiumResponse = action.payload
    },
    resetPayoutAndPremium: (state) => {
      state.payout = initialState.payout
      state.premium = initialState.premium
      state.premiumResponse = initialState.premiumResponse
    },
    reset: () => initialState,
  },
})

export const {
  setGender,
  setAge,
  setSmokerStatus,
  setPayoutAndPremium,
  setPremiumResponse,
  resetPayoutAndPremium,
  reset,
} = quoteSlice.actions
export default quoteSlice.reducer
