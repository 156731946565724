/**
 * Parses an ID number top a masked ID number
 * @param idNumber The ID number e.g 8910205072087
 * @returns The masked ID number e.g 891050 5072 08 7
 */
export function parseIdToMaskedId(idNumber: string): string {
  const one = idNumber.slice(0, 6)
  const two = idNumber.slice(6, 10)
  const three = idNumber.slice(10, 12)
  const four = idNumber.slice(12, 13)

  return `${one} ${two} ${three} ${four}`
}
