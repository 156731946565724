import React, {useState, createContext} from 'react'

type Props = {
  children: React.ReactNode
}

type InitialValues = {
  progress: number
  setProgress: (value: number) => void
}

const initialValues: InitialValues = {
  progress: 0,
  setProgress: (value) => value,
}

export const ProgressContext = createContext(initialValues)

export const ProgressProvider: React.FC<Props> = ({children}) => {
  const [progress, setProgress] = useState(initialValues.progress)

  return (
    <ProgressContext.Provider
      value={{
        progress: progress,
        setProgress: setProgress,
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}
