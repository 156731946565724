import React from 'react'
import styled, {css} from 'styled-components'

type ButtonVariant = 'primary' | 'secondary'

type Props = {
  onClick?: (event: React.MouseEvent) => void
  disabled?: boolean
  variant?: ButtonVariant
}

export const Button: React.FC<Props> = (props) => {
  const {children, onClick, disabled = false, variant = 'primary'} = props
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      type="button"
      variant={variant}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

const RippleStyle = () => css`
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: radial-gradient(circle, #e9e9e9 10%, transparent 10%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.4s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
`

const StyledButton = styled.button<{disabled: boolean; variant: ButtonVariant}>`
  ${RippleStyle}
  cursor: pointer;
  outline: inherit;
  background: ${(props) =>
    props.variant === 'primary'
      ? 'linear-gradient(90deg, #ffb800 0%, #ff820d 100%)'
      : 'linear-gradient(90deg, #6A3CC7 0%, #36008D 100%)'};
  color: var(--white);
  border: 0 none;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: var(--font-medium);
  line-height: 50px;
  font-family: var(--font-family);
  opacity: ${(props) => (props.disabled ? '0.6' : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: opacity 0.5s ease-in-out;
`
