import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface PersonalInformation {
  question1: string
  question2: string
  question3: string
  question4: string
  question5: string
}

const initialState: PersonalInformation = {
  question1: 'No',
  question2: 'No',
  question3: 'No',
  question4: 'No',
  question5: 'No',
}

const medicalQuestionsSlice = createSlice({
  name: 'medicalQuestions',
  initialState,
  reducers: {
    setQuestionAnswer(
      state,
      action: PayloadAction<{
        questionId: number
        value: string
      }>,
    ) {
      switch (action.payload.questionId) {
        case 1:
          state.question1 = action.payload.value
          break
        case 2:
          state.question2 = action.payload.value
          break
        case 3:
          state.question3 = action.payload.value
          break
        case 4:
          state.question4 = action.payload.value
          break
        case 5:
          state.question5 = action.payload.value
          break

        default:
          break
      }
    },
  },
})

export const {setQuestionAnswer} = medicalQuestionsSlice.actions
export default medicalQuestionsSlice.reducer
