import React from 'react'
import styled from 'styled-components'

export const PageContainer: React.FC = ({children}) => {
  return <Container>{children}</Container>
}

const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 992px) {
    max-width: 700px;
    justify-content: center;
  }
`
