import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'

export const TrustIcons = () => {
  return (
    <Container>
      <Image
        src="/images/paystack.png"
        alt="Sad emoji"
        width={320}
        height={83}
      />
      <Spacer />
      <Image src="/images/ssl.png" alt="Sad emoji" width={177} height={83} />
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
`

const Spacer = styled.div`
  width: calc(var(--spacing-unit));
`
