import React, {useContext} from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import {ProgressContext} from '../contexts'
import {motion} from 'framer-motion'
import {useMediaQuery} from 'react-responsive'
import {BackButton} from '.'
import {useRouter} from 'next/router'
import {UserInfo} from './userInfo'
import {useUser} from '@auth0/nextjs-auth0'

const LogoLink = ({children}) => {
  const {user, isLoading} = useUser()
  if (user && !isLoading) {
    return (
      <a href="https://www.aynjil.com/sales-partner-dashboard/">{children}</a>
    )
  }

  return children
}

export const Header: React.FC = () => {
  const {progress} = useContext(ProgressContext)
  const router = useRouter()
  const isTabletOrDesktop = useMediaQuery({query: '(min-width: 768px)'})

  const shouldShowBackButton = () => {
    if (
      router.pathname === '/confirmation-of-policy' ||
      router.pathname === '/confirmation' ||
      router.pathname === '/'
    ) {
      return false
    }
    return true
  }

  return (
    <Wrapper>
      <div>
        {!isTabletOrDesktop && shouldShowBackButton() && <BackButton />}
        <LogoLink>
          <Image
            src="/images/logo.svg"
            alt="Aynjil Logo"
            width={78}
            height={24}
            priority={true}
          />
        </LogoLink>
      </div>
      <UserInfo />
      <Progress animate={{width: `${progress}%`}} />
    </Wrapper>
  )
}

const Wrapper = styled.header`
  position: relative;
  height: 56px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-unit);
`

const Progress = styled(motion.div)`
  height: 2px;
  background-color: var(--purple);
  position: absolute;
  bottom: 0;
  left: 0;
`
