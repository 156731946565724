import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import {useRouter} from 'next/router'
import {useProgress} from '../hooks'

export const BackButton: React.FC = (props) => {
  const router = useRouter()
  const {decrementProgress} = useProgress()
  return (
    <Button
      className="back"
      {...props}
      data-testid="back-button"
      onClick={() => {
        router.back()
        decrementProgress()
      }}
    >
      <Image
        src="/icons/caret-left.svg"
        alt="Aynjil Logo"
        width={18}
        height={18}
        priority={true}
      />
    </Button>
  )
}

const Button = styled.button`
  cursor: pointer;
  outline: inherit;
  background-color: transparent;
  border: 0 none;
  width: 50px;
  height: 50px;
  margin-left: -16px;

  @media (min-width: 768px) {
    margin-left: 0;
  }

  & > div {
    margin: 5px 4px 0 0 !important;
  }
`
