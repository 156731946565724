import React from 'react'
import styled from 'styled-components'

export const Divider: React.FC = (props) => {
  return <Line {...props} />
}

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7d4f9;
`
