import useSWR from 'swr'
import {ApiPremiumResponseV2} from '../types'
import {useAppDispatch} from '../hooks'
import {setPremiumResponse} from '../features/quote/quote-slice'

/**
 * Hook used to fetch premium
 * @param age string
 * @param gender string
 * @param isSmoker boolean
 */
export function usePremium(age, gender: string, isSmoker: boolean) {
  const dispatch = useAppDispatch()

  const {data, error} = useSWR<ApiPremiumResponseV2>(
    `/api/premiumV2?age=${age}&gender=${gender}&smoker=${isSmoker}`,
    (url) => fetch(url).then((res) => res.json()),
  )

  data && dispatch(setPremiumResponse(data))

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}
